import React, { useState, useRef, useEffect } from 'react';
import { motion } from "framer-motion";
import './css/landing.css'
import { Link } from 'gatsby';
import ReactPlayer from 'react-player'
import Big from "../../images/big.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links";
//Elements 
import Para from '../../elements/Paragraph'
import ReadMore from '../../elements/ReadMore'
import ReadMoreNoLink from '../../elements/ReadMoreNoLink'
import Title from '../../elements/Title'
import FeaturedPost from '../../elements/FeaturedPost'
import FeaturedProjectGrid from './FeaturedProjectGrid';
import RecoSliderHome from '../../elements/RecoSliderHome';
import GIF from '../../images/bikes.jpg'
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTimes,
} from '@fortawesome/free-solid-svg-icons'


import styled, { css } from 'styled-components'
//slider
import Slider from '../../elements/Slider'
import { AnimatedHeadingContainer, CTA } from '../../static/css/style'
import FeaturedProject from '../../elements/FeaturedProject';

const Div100 = styled.div`
    padding:8em 0 4em 0;
    display:flex;
    flex-direction:row;
    @media screen and (max-width: 991px) {
        padding: 8em 0px 4em;
        flex-direction:column;
    }
    @media screen and (max-width: 479px) {
        padding: 2em 0px 2em;
    }
`;

const Div30 = styled.div`
    padding-right:1em;
    width:30%;
    display:flex;
    flex-direction:column;
    justify-content: flex-end;
    padding-bottom:5%;
    @media screen and (max-width: 991px) {
        justify-content: center;
        width:100%;
        padding-bottom:0%;
    }
    @media screen and (max-width: 767px) {}
    @media screen and (max-width: 479px) {
        
    }
`;
const Div70 = styled.div`
    width:70%;
    height:570px;
    @media screen and (max-width: 991px) {
        width:100%;
        height:auto;
        margin-bottom:30px;
    }
    @media screen and (max-width: 479px) {
      
    }
`;
const ProjectImage = styled.img`
    height:100%;
    width:100%;
    object-fit: cover;
    object-position: 50% 100%;

`;
const HugeHeading = styled.h1`
    font-family: 'Begum', sans-serif;
    -webkit-text-stroke: 1px #fff;
    font-size: 29em;
    font-weight: 600;
    line-height: 100%;
    margin-left: -20px;
    text-align: left;
    color: transparent;
    & span{
        color:#4ab9e9;
        -webkit-text-stroke: 1px #4ab9e9;
    }
    @media screen and (min-width: 1920px){}
    @media screen and (max-width: 991px) {
        font-size: 290px;
    }
    @media screen and (max-width: 767px) {}
    @media screen and (max-width: 479px) {
        font-size: 13em;
        margin-left: -10px;
    }
`;
const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 0%;
    overflow:hidden;
    transition:all 0.3s ease;
    background: rgba(0, 0, 0, 0.7);
    z-index:9999;
    opacity:0;
    backdrop-filter: blur(10px);
    ${props => props.open && css`
        width: 100%;
        height: 100%;
        opacity:1;
    `}
`;
const ModalInner = styled.div`
    position:absolute;
    background: transparent;
    width: 70%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
`;
const Showreel = styled.div`
    height:100%;
    width:100%;
    object-fit: cover;
    object-position: 50% 100%;
    display: flex;
    flex-direction: column;
`;
const closeIcon = {
    marginLeft: "auto",
    cursor: "pointer",
    marginBottom: "20px",
};
const TitleWrap = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    height:90vh;
    background:transparent;
    @media screen and (max-width: 991px) {
        height:90vh;
    }
    @media screen and (max-width: 767px) {

    }
    @media screen and (max-width: 479px) {
        height:90vh;
    }
`;
const TitleContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:fit-content;
    height:auto;
    background:transparent;
    position:relative;
`;
const TitleRow = styled(motion.div)`
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height:auto;
    position:relative;
`;
const BannerHeading = styled.h1`
    width: ${props => props.width || 'auto'};
    font-family: 'Begum', sans-serif;
    font-size: 80px;
    position:relative;
    line-height: 1.22;
    text-align: center;
    color: #fff;
    overflow:hidden;
    font-weight:600;
    transition:all 0.5s ease;
    @media screen and (max-width: 991px) {
        font-size:3.8em;
    }
    @media screen and (max-width: 767px) {
        font-size:2em;
    }
    @media screen and (max-width: 479px) {
        font-size:2.1em;
    }
    @media screen and (max-width: 325px) {
        font-size:1.95em;
    }
`;
const BannerSubHeadingOne = styled(motion.h4)`
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    position:absolute;
    top:${props => props.show ? '-40px' : '0px'};
    opacity:${props => props.show ? '1' : '0'};
    left:0;
    text-align: left;
    color: #fff;
    font-weight:400;
    transition:all 0.5s ease;
    transform:${props => props.show ? 'scale(1)' : 'scale(1)'};
    @media screen and (max-width: 991px) {
        font-size:1.8em;
    }
    @media screen and (max-width: 479px) {
        font-size:18px;
    }
    @media screen and (max-width: 325px) {
        font-size:16px;
    }
`;
const BannerSubHeadingTwo = styled(BannerSubHeadingOne)`
    top:auto;
    left:auto;
    bottom:${props => props.show ? '-40px' : '0px'};
    right:0;
    transform:${props => props.show ? 'scale(1)' : 'scale(1)'};
`;
const SectionTwo = styled.div`
    display:flex;
    min-height:100vh;
    flex-direction:column;
    justify-content:center;
    align-items:flex-end;
    width:100%;
    max-width:60%;
    margin-left:auto;
    margin-right:0px;
    height:auto;
    position:relative;
    @media screen and (max-width: 991px) {
        min-height:auto;
        max-width:80%;
        padding:100px 0 100px 0;
    }
    @media screen and (max-width: 479px) {
        max-width:100%;
    }
`;
const ExploreBlock = styled(Link)`
    display:flex;
    justify-content:flex-end;
    align-items: baseline;
    width:auto;
    margin-right:0px;
    margin-left:auto;
    text-decoration:none;
    transition:all 0.3s ease;
    cursor:pointer;
    &:hover #exploreDot{
        width:15px;
        height:15px;
    }
    @media screen and (max-width: 991px) {
        #exploreDot{
            width:15px;
            height:15px;
        }
    }
    @media screen and (max-width: 767px) {
        #exploreDot{
            width:12px;
            height:12px;
            margin-top:2px;
        }
    }
    @media screen and (max-width: 479px) {
        align-items:center;
    }
`;
const ExploreDot = styled.div`
    width:0px;
    height:0px;
    background-color:#4AB9E9;
    border-radius:100px;
    margin-left:10px;
    transition:all 0.3s ease;
`;
const BigChange = styled.img`
    width:500px;
    height:auto;
    object-fit:contain;
    @media screen and (max-width: 991px) {
        width:400px;
        margin-top:20px;
    }
    @media screen and (max-width: 479px) {
        width:80%;
    }
`;
const ExploreText = styled.p`
    margin-bottom:0px;
    font-size:25px;
    line-height:1;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    font-weight:400;
    @media screen and (max-width: 991px) {
        font-size:25px;
    }
    @media screen and (max-width: 479px) {
        font-size:16px;
    }
`;
const FeaturedHeading = styled.h2`
    margin-bottom:0px;
    font-size:30px;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    font-weight:100;
    @media screen and (max-width: 991px) {
        font-size:25px;
    }
    @media screen and (max-width: 479px) {
        font-size:22px;
    }
`;
const Landing = (props) => {
    const [modalState, setModalState] = useState(false);
    const [subOneState, setSubOne] = useState(false);
    const [subTwoState, setSubTwo] = useState(false);
    const closeModal = () => {
        setModalState(modalState => false);
        // handleStopVideo();
    };
    const openModal = () => {
        setModalState(modalState => true);
        // handlePlayVideo();
    };
    useEffect(() => {
        setSubOne(false);
        setSubTwo(false);
    }, []);
    const toggleSubOne = () => {
        setSubOne(!subOneState);
    };
    const toggleSubTwo = () => {
        setSubTwo(!subTwoState);
    };
    // const vidRef = useRef(null);
    // const handlePlayVideo = () => {
    //     vidRef.current.play();
    // }
    // const handleStopVideo = () =>{
    //     vidRef.current.pause();
    // }

    return (
        <div className="bodyBlack">
            <div className="w-90" style={{ "position": "relative" }}>
                <Modal open={modalState} >
                    <ModalInner>

                        {/* <ProjectImage src={GIF}></ProjectImage> */}

                        <Showreel>
                            <FontAwesomeIcon icon={faTimes} size="2x" color="#fff" style={closeIcon} onClick={closeModal} />
                            <ReactPlayer playing={modalState} controls={true} width={"100%"} height={"100%"} url="https://kwazifiles.s3.us-east-2.amazonaws.com/Showreel+-+Kwazi+21'+-+Final1.mp4">

                            </ReactPlayer >
                        </Showreel>

                        {/* <Showreel ref={vidRef}>
                        <source src={"https://kwazifiles.s3.us-east-2.amazonaws.com/Showreel+-+Kwazi+21'+-+Final1.mp4"} type='video/mp4' />
                    </Showreel> */}
                    </ModalInner>
                </Modal>
                {/* <TitleWrap>
                    <TitleContainer>
                        <BannerSubHeadingOne show={subOneState}>
                            build brands that
                        </BannerSubHeadingOne>
                        <TitleRow>
                            <BannerHeading style={{ cursor: "pointer" }} onMouseEnter={toggleSubOne} onMouseLeave={toggleSubOne}  >create&nbsp;</BannerHeading>
                            <BannerHeading>the&nbsp;</BannerHeading>
                            <BannerHeading style={{ cursor: "pointer" }} onMouseEnter={toggleSubTwo} onMouseLeave={toggleSubTwo}>difference</BannerHeading>
                        </TitleRow>
                        <BannerSubHeadingTwo show={subTwoState}>
                            through meaningful design
                        </BannerSubHeadingTwo>
                    </TitleContainer>
                </TitleWrap> */}
                <BannerText />
                {/* <AnimatedHeadingContainer >
                    <BannerHeading>
                        <span className="light">we design better</span><br />
                        <span className="light">ways to</span>
                        <div className="rw-words rw-words-1">
                            <span>experience</span>
                            <span>showcase</span>
                            <span>narrate</span>
                        </div>
                        <br />
                        <span className="light">your</span>
                        <div className="rw-words rw-words-1">
                            <span>brand</span>
                            <span>product</span>
                            <span>stories</span>
                        </div>
                    </BannerHeading>
                </AnimatedHeadingContainer> */}
                {/* <div onClick={openModal}>
                    <ReadMoreNoLink>watch something cool</ReadMoreNoLink>
                </div> */}
                <AnchorLink to="/#explore" title="First Section" className="text-deco-none">
                    <ReadMoreNoLink>dive in</ReadMoreNoLink>
                </AnchorLink>
                <SectionTwo id="explore">
                    <Title color="#fff" width="100%" align="right" size="lg">we are a multi-disciplinary, excellence-led design organization.</Title>
                    <Para color="#fff" LineHeight="1.4" width="80%" align="right">We find our passion in creating impactful design that expresses impactful ideas, products, and brands. Our work in branding, UI/UX, communication & campaign design, strategy and spatial design, finds meaning through partnership, with our clients and their brands.</Para>
                    <ExploreBlock to="/about/">
                        <ExploreText>explore</ExploreText>
                        <ExploreDot id="exploreDot"></ExploreDot>
                    </ExploreBlock>
                </SectionTwo>
                <FeaturedProjectGrid />
                {/* <FeaturedProject></FeaturedProject> */}
                <Div100>
                    <Div70>
                        <Title color="#fff" >the small things for</Title>
                        <BigChange src={Big}></BigChange>
                        {/* <HugeHeading>b<span>i</span>g</HugeHeading> */}
                        <Title color="#fff">change <span>!</span></Title>
                    </Div70>
                    <Div30 >
                        <Para LineHeight="1.25" color="#fff">When we design for our clients, we focus on the small things. Each element, each typeface and each icon. The result adds up to be a monumental design language that allows the brand to illustrate <em>big change</em>. A cumulative cultivation of creativity. </Para>
                        <ReadMore link="/about" align="right" marginRight>know us</ReadMore>
                    </Div30>
                </Div100>
                {/* <Title size="xl" weight="bold" lineHeight="100%" top="5%" bottom="5%">featured case studies...</Title>
                <Slider case="true" homePage="true"></Slider> */}
            </div>
            <FeaturedPost></FeaturedPost>
            <div className="w-90">
                <FeaturedHeading>read on...</FeaturedHeading>
                {/* <Slider case="false" homePage="true"></Slider> */}
            </div>
            <div className="w-95" style={{ paddingBottom: "100px" }}>
                <RecoSliderHome case="false" homePage="true" />
            </div>
        </div>
    );
};
function BannerText() {
    const [lastYPos, setLastYPos] = React.useState(0);
    const [scrollDown, setScrollDown] = useState(false);
    const [scrollUp, setScrollUp] = useState(false);
    React.useEffect(() => {
        function handleScroll() {
            const yPos = window.scrollY;
            const isScrollingUp = yPos < lastYPos;
            const isScrollingDown = yPos > lastYPos;
            console.log(yPos)
            console.log(isScrollingDown)
            setScrollDown(isScrollingDown)
            setScrollUp(isScrollingUp)
            setLastYPos(yPos);
        }

        window.addEventListener("scroll", handleScroll, false);

        return () => {
            window.removeEventListener("scroll", handleScroll, false);
        };
    }, [lastYPos]);

    return (
        <TitleWrap>
            <TitleContainer>
                <BannerSubHeadingOne
                    initial={{ opacity: 0, top: '-40px' }}
                    animate={{ opacity: scrollUp ? 1 : 0, top: scrollUp ? '-40px' : '0px' }}
                    transition={{ all: { duration: 0.2 } }}
                >
                    build brands that
                </BannerSubHeadingOne>
                <TitleRow
                    initial={{ scale: 0.5, opacity: 0 }}
                    whileInView={{ scale: 1, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.3 }}
                >
                    <BannerHeading>create the difference</BannerHeading>
                </TitleRow>
                <BannerSubHeadingTwo
                    initial={{ opacity: 0, bottom: '-40px' }}
                    animate={{ opacity: scrollDown ? 1 : 0, bottom: scrollDown ? '-40px' : '0px' }}
                    transition={{ all: { duration: 0.2 } }}
                >
                    through meaningful design
                </BannerSubHeadingTwo>
            </TitleContainer>
        </TitleWrap>
    );
}

export default Landing;