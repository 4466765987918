import React from 'react';
import styled from 'styled-components'
import { graphql, StaticQuery } from "gatsby";
import { Link } from 'gatsby';
const FeaturedWrap = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    width:100%;
    height:auto;
    padding:40px 5% 40px 5%;
    margin:0px 0 200px 0;
    @media screen and (max-width: 991px) {
        margin:0px 0 50px 0;
        padding:40px 0% 0px 0%;
    }
`;
const FeaturedHeading = styled.h2`
    margin-bottom:0px;
    font-size:30px;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    font-weight:100;
    @media screen and (max-width: 991px) {
        font-size:25px;
    }
    @media screen and (max-width: 479px) {
        font-size:22px;
    }
`;
const FeaturedGrid = styled.div`
    display:grid;
    grid-template-columns:1fr 1fr;
    grid-template-rows:auto;
    width:100%;
    margin-top:80px;
    grid-gap:40px;
    @media screen and (max-width: 991px) {
        grid-template-columns:1fr;
        grid-template-rows:auto;
        grid-gap:170px;
    }
    @media screen and (max-width: 767px) {
        grid-gap:40px;
    }
    @media screen and (max-width: 479px) {
        
    }
`;
const GridTile = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    align-items:flex-start;
    width:100%;
    height:100%;
    &.two{
        transform:translateY(80px);
        align-items:flex-end;
    }
    &.three{
        transform:translateY(0px);
    }
    &.four{
        transform:translateY(250px);
        align-items:flex-end;
    }
    @media screen and (max-width: 991px) {
        &.two{
            transform:translate(20px,40px);
            align-items:flex-end;
        }
        &.three{
            transform:translate(0px,20px);
        }
        &.four{
            transform:translate(0px,0px);
            align-items:flex-end;
        }
    }
    @media screen and (max-width: 479px) {
        &.two{
            transform:translate(0px,0px);
            align-items:flex-start;
        }
        &.three{
            transform:translate(0px,0px);
        }
        &.four{
            transform:translate(0px,0px);
            align-items:flex-start;
        }
    }
`;

const TileContentContainer = styled.div`
    background:white;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    height:auto;
    border-bottom-left-radius:20px;
    border-bottom-right-radius:20px;
    transition:all 0.3s ease;
    position:absolute;
`;
const Title = styled.h3`
    font-size:20px;
    font-family: 'Montserrat', sans-serif;
    color: #191919;
    font-weight:600;
    opacity:1;
    width:100%;
    margin:0px;
    transition:all 0.3s ease;
    @media screen and (max-width: 991px) {
       
    }
    @media screen and (max-width: 767px) {

    }
    @media screen and (max-width: 479px) {

    }
`;
const Description = styled.p`
    color:#191919;
    margin-bottom:0px;
    font-size:15px;
    font-family: 'Montserrat', sans-serif;
    font-weight:600;
    overflow:hidden;
    transition:all 0.3s ease;
    height:auto;
    max-height:0px;
    opacity:0;
    margin-top:0px;
    @media screen and (max-width: 479px) {
        max-height:auto;
        opacity:1;
        color:rgba(25,25,25,0.5);
    }
`;
const Tile = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    height:auto;
    cursor:pointer;
    position:relative;
    &:hover ${Title}{
        opacity:0.5;
    }
    &:hover ${Description}{
        max-height:60px;
        opacity:1;
        margin-top:10px;
    }
    @media screen and (max-width: 991px) {
    
        &:hover ${Description}{
            max-height:none;
        }
    }
    @media screen and (max-width: 479px) {
        opacity:1;
        & ${Description}{
            height:auto;
            max-height:none;
            opacity:1;
            margin-top:10px;
        }
        &:hover ${Description}{
            max-height:none;
        }
        &:hover ${Title}{
            opacity:1;
        }
    }
`;
const TileOneImage = styled.img`
    width:100%;
    height:433px;
    border-radius:20px;
    margin:0px;
    object-fit:cover;
    object-position:center;
    @media screen and (min-width: 1536px) {
        height:551px;
    }
    @media screen and (max-width: 479px) {
        height:250px;
    }
`;
const TileTwoImage = styled.img`
    width:100%;
    height:358px ;
    border-radius:20px;
    margin:0px;
    object-fit:cover;
    object-position:left;
    @media screen and (min-width: 1536px) {
        height:434px;
    }
    @media screen and (max-width: 479px) {
        height:250px;
    }
`;
const TileFourImage = styled.img`
    width:100%;
    height:555px;
    border-radius:20px;
    margin:0px;
    object-fit:cover;
    object-position:center;
    @media screen and (min-width: 1536px) {
        height:672px;
    }
    @media screen and (max-width: 479px) {
        height:250px;
    }
`;
const TileOne = styled(Tile)`
    width:348px;
    & ${TileContentContainer}{
        right:-20px;
        top:353px;
        border-top-left-radius:20px;
        border-top-right-radius:20px;
        padding:30px 15px 30px 15px;
        width:100%;
    }
    &:hover ${TileContentContainer}{
        right:0;
        top:413px;
        border-top-left-radius:0px;
        border-top-right-radius:0px;
        padding:20px 15px 20px 15px;
        width:100%;
    }
    @media screen and (min-width: 1536px) {
        width:421px;
        & ${TileContentContainer}{
            top:375px;
        }
        &:hover ${TileContentContainer}{
            top:453px;
        }
    }
    @media screen and (max-width: 479px) {
        width:100%;
        & ${TileContentContainer}{
            right:0px;
            top:0px;
            position:relative;
            border-top-left-radius:0px;
            border-top-right-radius:0px;
            padding:20px 15px 20px 15px;
            transform:translateY(-20px);
            width:100%;
        }
        &:hover ${TileContentContainer}{
            right:0;
            top:0px;
            transform:translateY(-20px);
            border-top-left-radius:0px;
            border-top-right-radius:0px;
            padding:20px 15px 20px 15px;
            width:100%;
        }
    }
`;
const TileTwo = styled(Tile)`
    width:479px;
    & ${TileContentContainer}{
        left:-20px;
        top:228px;
        border-top-left-radius:20px;
        border-top-right-radius:20px;
        padding:30px 15px 30px 15px;
        width:100%;
    }
    &:hover ${TileContentContainer}{
        left:0;
        top:338px;
        border-top-left-radius:0px;
        border-top-right-radius:0px;
        padding:20px 15px 20px 15px;
        width:100%;
    }
    @media screen and (min-width: 1536px) {
        width:579px;
        & ${TileContentContainer}{
            top:278px;
        }
        &:hover ${TileContentContainer}{
            top:373px;
        }
    }
    @media screen and (max-width: 479px) {
        width:100%;
        & ${TileContentContainer}{
            right:0px;
            top:0px;
            position:relative;
            border-top-left-radius:0px;
            border-top-right-radius:0px;
            padding:20px 15px 20px 15px;
            transform:translate(20px,-20px);
            width:100%;
        }
        &:hover ${TileContentContainer}{
            right:0;
            top:0px;
            transform:translate(0px,-20px);
            border-top-left-radius:0px;
            border-top-right-radius:0px;
            padding:20px 15px 20px 15px;
            width:100%;
        }
    }
`;
const TileFour = styled(Tile)`
    width:435px;
    & ${TileContentContainer}{
        right:-20px;
        top:385px;
        border-top-left-radius:20px;
        border-top-right-radius:20px;
        padding:30px 15px 30px 15px;
        width:100%;
    }
    &:hover ${TileContentContainer}{
        right:0;
        top:535px;
        border-top-left-radius:0px;
        border-top-right-radius:0px;
        padding:20px 15px 20px 15px;
        width:100%;
    }
    @media screen and (min-width: 1536px) {
        width:526px;
        & ${TileContentContainer}{
            top:505px;
        }
        &:hover ${TileContentContainer}{
            top:605px;
        }
    }
    @media screen and (max-width: 479px) {
        width:100%;
        & ${TileContentContainer}{
            right:0px;
            top:0px;
            position:relative;
            border-top-left-radius:0px;
            border-top-right-radius:0px;
            padding:20px 15px 20px 15px;
            transform:translateY(-20px);
            width:100%;
        }
        &:hover ${TileContentContainer}{
            right:0;
            top:0px;
            transform:translateY(-20px);
            border-top-left-radius:0px;
            border-top-right-radius:0px;
            padding:20px 15px 20px 15px;
            width:100%;
        }
    }
`;
export default (props) => (
    <StaticQuery
        query={graphql`
      query { 
          allWpPost(filter: {categories: {nodes: {elemMatch: {name: {eq: "Case Study"}}}}, summary: {featuredPost: {eq: true}}}, sort: {order: DESC, fields: date}, limit: 4) {
                nodes {
                title
                content
                date
                slug
                excerpt
                summary {
                    summary
                    tileImage {
                        mediaItemUrl
                        altText
                      }
                  }
                categories {
                    nodes {
                        slug
                        name
                    }
                }
                tags {
                    nodes {
                        slug
                        name
                    }
                }
                featuredImage {
                    node {
                        mediaItemUrl
                        altText
                    }
                }
                }
            }
          }
      `}

        render={data => (
            <FeaturedWrap>
                <FeaturedHeading>just some of our latest work...</FeaturedHeading>
                <FeaturedGrid>
                    {data.allWpPost.nodes[0] &&
                        <Link to={"/project/" + data.allWpPost.nodes[0].slug + "/"} style={{ outline: "none", textDecoration: "none" }}>
                            <GridTile className="one">
                                <TileOne>
                                    <TileOneImage src={data.allWpPost.nodes[0].summary.tileImage.mediaItemUrl} alt={data.allWpPost.nodes[0].summary.tileImage.altText} />
                                    <TileContentContainer id="cont1">
                                        <Title>{data.allWpPost.nodes[0].title}</Title>
                                        <Description>
                                            {data.allWpPost.nodes[0].summary.summary}
                                        </Description>
                                    </TileContentContainer>
                                </TileOne>
                            </GridTile>
                        </Link>
                    }
                    {data.allWpPost.nodes[1] &&
                        <Link to={"/project/" + data.allWpPost.nodes[1].slug + "/"} style={{ outline: "none", textDecoration: "none" }}>
                            <GridTile className="two">
                                <TileTwo>
                                    <TileTwoImage src={data.allWpPost.nodes[1].summary.tileImage.mediaItemUrl} alt={data.allWpPost.nodes[1].summary.tileImage.altText} />
                                    <TileContentContainer id="cont1">
                                        <Title>{data.allWpPost.nodes[1].title}</Title>
                                        <Description>
                                            {data.allWpPost.nodes[1].summary.summary}
                                        </Description>
                                    </TileContentContainer>
                                </TileTwo>
                            </GridTile>
                        </Link>
                    }
                    {data.allWpPost.nodes[2] &&
                        <Link to={"/project/" + data.allWpPost.nodes[2].slug + "/"} style={{ outline: "none", textDecoration: "none" }}>
                            <GridTile className="three">
                                <TileTwo>
                                    <TileTwoImage src={data.allWpPost.nodes[2].summary.tileImage.mediaItemUrl} alt={data.allWpPost.nodes[2].summary.tileImage.altText} />
                                    <TileContentContainer id="cont1">
                                        <Title>{data.allWpPost.nodes[2].title}</Title>
                                        <Description>
                                            {data.allWpPost.nodes[2].summary.summary}
                                        </Description>
                                    </TileContentContainer>
                                </TileTwo>
                            </GridTile>
                        </Link>
                    }
                    {data.allWpPost.nodes[3] &&
                        <Link to={"/project/" + data.allWpPost.nodes[3].slug + "/"} style={{ outline: "none", textDecoration: "none" }}>
                            <GridTile className="four">
                                <TileFour>
                                    <TileFourImage src={data.allWpPost.nodes[3].summary.tileImage.mediaItemUrl} alt={data.allWpPost.nodes[3].summary.tileImage.altText} />
                                    <TileContentContainer id="cont1">
                                        <Title>{data.allWpPost.nodes[3].title}</Title>
                                        <Description>
                                            {data.allWpPost.nodes[3].summary.summary}
                                        </Description>
                                    </TileContentContainer>
                                </TileFour>
                            </GridTile>
                        </Link>
                    }
                </FeaturedGrid>
            </FeaturedWrap>
        )}
    />
)
