import React, { useState } from 'react';
import styled, { css } from 'styled-components'
import Background from '../images/image17.jpg'
import Para from '../elements/Paragraph'
import Title from '../elements/Title'
import ReadMore from '../elements/ReadMore'
import { graphql, StaticQuery, Link } from "gatsby";

const FeaturedBlockParent = styled.div`
    width:90%;
    margin:100px auto 150px auto;
    position:relative;
    height:561px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 479px) {
        height:auto;
        flex-direction:column;
        margin:50px auto 60px auto;
    }
`;
const FeaturedBlock = styled.div`
    height:100%;
    width:80%;
    margin-right:auto;
    border-radius:20px;
    background-size: cover;
    background-position: 50% 50%;
    transition:all 0.35s ease;
    display:flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 991px) {
        
    }
    @media screen and (max-width: 767px) {

    }
    @media screen and (max-width: 479px) {
        width:95%;
        height:262px;
    }
`;
const OpacityBlock = styled.div`
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width:100%;
    height:100%;
    opacity: 0.3;
    background-color: #6a6a6a;
`;

const ContentHolder = styled.div`
    z-index:0;
    position:absolute;
    right:0;
    width:665px;
    height:auto;
    padding: 2em 1.15em 2em 0;
    background:#fff;
    min-height: 387px;
    border-radius:20px;
    
    @media screen and (max-width: 991px) {
        width:85%;
    }
    @media screen and (max-width: 479px) {
        width:95%;
        margin-left:auto;
        position:relative;
        transform:translate(0%,-50px);
    }
`;
const Tag = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    width: 100%;
    padding: 20px 10px 20px 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #4AB9E9;
    font-size:20px;
    font-family: 'Century Gothic', sans-serif;
    font-weight:bold;
    color:#fff;
    border-radius:20px;
      @media screen and (max-width: 479px) {
        font-size:16px;
        padding: 12px 5px 12px 5px;
        border-radius:15px;
    }
`;
const PostDate = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.;
    text-align: left;
    color: #000000;
`;
const ContentContainer = styled.div`
    width:90%;
    margin-left:auto;
    padding: 4% 3% 1% 0%;
    & p.featuredPara{
        width:90%;
    }
    @media screen and (max-width: 479px) {
        & p.featuredPara{
            width:100%;
        }
    }
`;

const TagWrap = styled.div`
    width:100%;
    display:flex;
   position:absolute;
   top:-20px;
   left:20px;
   z-index:1;
`;
const DivPara = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size: ${props => props.size == 'sm' ? '0.9em' : props.size == 'md' ? '1.1em' : props.size == 'lg' ? '1.45em' : '18px'};
    font-weight: ${props => props.fontWeight || "normal"};
    line-height: ${props => props.LineHeight || "1.2"};
    letter-spacing: normal;
    text-align: ${props => props.align || 'left'};
    z-index: ${props => props.zIndex || '0'};
    color: ${props => props.color || "#000"};
    margin-bottom:${props => props.marginBottom || "1.45em"};
    margin-top:${props => props.top || ""};
    margin-right:${props => props.right || ""};
    width:${props => props.width || "100%"};
    padding-left:${props => props.paddingLeft || "0"};
    padding-right:${props => props.paddingRight || "0"};
    padding-top:${props => props.paddingTop || "0"};
    padding-bottom:${props => props.paddingBottom || "0"};
    & span{
        font-weight: bold;
        color: #4ab9e9;
    }
    & bold{
        font-weight:bold;
        border-bottom:2px solid #4ab9e9;
    }
    @media screen and (max-width: 479px) {
        width:${props => props.wfm == "true" ? "100% !important" : props.width || "100%"};
        font-size: ${props => props.size == 'sm' ? '12px' : props.size == 'md' ? '14px' : props.size == 'lg' ? '1.15em' : '16px'};
    }
`;
export default (props) => (
    <StaticQuery
        query={graphql`
      query {
            allWpPost(sort: {order: DESC, fields: date} filter: {categories: {nodes: {elemMatch: {name: {eq: "Journal"}}}}}) {
                nodes {
                title
                content
                date
                slug
                excerpt
                summary {
                    summary
                  }
                categories {
                    nodes {
                    slug
                    name
                    }
                }
                tags {
                    nodes {
                    slug
                    name
                    }
                }
                featuredImage {
                    node {
                    mediaItemUrl
                    }
                }
                }
            }
          }
      `}

        render={data => (
            <FeaturedBlockParent>
                <FeaturedBlock style={{ backgroundImage: `url(${data.allWpPost.nodes[0].featuredImage.node.mediaItemUrl})` }}>

                </FeaturedBlock>
                <ContentHolder>
                    <TagWrap>
                        {data.allWpPost.nodes[0].tags.nodes.map(({ node }, index) => (
                            <Link to={"/tag/" + data.allWpPost.nodes[0].tags.nodes[index].slug + "/"} className="text-deco-none mr-4" key={index}>
                                <Tag>#{data.allWpPost.nodes[0].tags.nodes[index].name}</Tag>
                            </Link>
                        ))}
                    </TagWrap>
                    {/* <Tag><Para marginBottom="0" fontWeight="bold" color="#191919">#Tag Name</Para></Tag> */}
                    <ContentContainer>
                        <Title size="xs">{data.allWpPost.nodes[0].title}</Title>
                        <DivPara className="featuredPara" dangerouslySetInnerHTML={{ __html: data.allWpPost.nodes[0].excerpt }}>

                        </DivPara>

                        <ReadMore link={"/journal/" + data.allWpPost.nodes[0].slug + "/"} align="right" color="#191919">read now</ReadMore>
                    </ContentContainer>
                </ContentHolder>
            </FeaturedBlockParent>
        )}
    />
)