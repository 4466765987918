import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Landing from "../views/Landing/Landing"
const IndexPage = () => (
  <Layout overlay={false} navHeight="auto">
    <SEO title="Home" url="https://www.kwazi.in" />
    <Landing></Landing>
  </Layout>
)
export default IndexPage
